<template>
  <div class="main-body">
    <div class="page-attention">
      <p>商城添加商品的时候需要选择对应的商品分类,用户可以根据商品分类搜索商品。</p>
      <p>点击商品分类名前“+”符号，显示当前商品分类的下级分类。</p>
      <p>商品属性是前台搜索分类查询商品之后可以通过商品的属性进行进一步搜索。</p>
      <p>可通过拖拽进行分类排序</p>
    </div>
    <div class="table-operator">
      <a-button type="primary" @click="goTo('/goods/category/add')">添加商品分类</a-button>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'image'">
          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        </template>
        <template v-if="column.key === 'sort'">
          <a-input-number v-model:value="record.sort" :min="0" :max="100000" />
        </template>
        <template v-if="column.key === 'is_show'">
          <span v-if="record.is_show == 0"> 显示 </span>
          <span v-else>不显示</span>
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button type="link" size="small" @click="goTo('/goods/edit')">编辑</a-button>
            <a-button type="link" size="small" danger>删除</a-button>
          </a-space>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const goTo = (path) => {
      router.push(path);
    };

    onMounted(() => {});

    return {
      goTo,
      dataSource: ref([
        {
          category_name: "女装",
          image:
            "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          sort: 0,
          is_show: 0,
          children: [
            {
              category_name: "内衣",
              image:
                "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
              sort: 0,
              is_show: 0,
            },
            {
              category_name: "外套",
              image:
                "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
              sort: 0,
              is_show: 0,
            },
            {
              category_name: "袜子",
              image:
                "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
              sort: 0,
              is_show: 0,
            },
          ],
        },
        {
          category_name: "男装",
          image:
            "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          sort: 0,
          is_show: 0,
        },
        {
          category_name: "童装",
          image:
            "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          sort: 0,
          is_show: 0,
        },
      ]),
      columns: [
        {
          title: "分类名称",
          dataIndex: "category_name",
          key: "category_name",
          width: "30%",
          rowDrag: true,
        },
        {
          title: "图片",
          dataIndex: "image",
          key: "image",
          width: "20%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          width: "20%",
        },
        {
          title: "是否显示",
          dataIndex: "is_show",
          key: "is_show",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "20%",
        },
      ],
    };
  },
});
</script>